<template>
  <Signup />
</template>

<script>
import Signup from '@/components/Signup.vue';

export default {
  components: { Signup },
};
</script>
